import React from 'react';

import MainLayout from 'components/Layout/MainLayout';

// import Page from './layout/MainLayout'

// const Wrapper = styled(Page)`
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `

const PageLoader: React.FC = () => (
  <MainLayout bg={`/images/BG-01`}>
    <>Spinner nakrab</>
  </MainLayout>
);

export default PageLoader;
