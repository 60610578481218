import { useEffect } from 'react';

import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import styled from 'styled-components';
// import useConnectWallet from '../../hooks/useConnectWallet'
// import { isEmpty } from 'lodash';
// import { UALJs } from 'ual-plainjs-renderer';
// import { Anchor } from 'ual-anchor'         // Anchor Wallet
// import { Wax } from '@eosdacio/ual-wax';    // WAX Cloud Wallet
// import { SUPPORTED_CHAINS, APP_NAME } from '../../config/contract'

const Navbar = styled.div`
  background-image: url('/images/menubar.svg');
  background-size: inherit;
  background-repeat: no-repeat;
  position: fixed;
  height: 100px;
  padding-right: 16px;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Item = styled.div`
  display: flex;
`;

const ItemNumber = styled.div`
  background-color: #fff;
  padding: 8px 16px 8px 54px;
  border-radius: 16px;
  margin-right: 8px;
  font-weight: bold;
  position: relative;
  img {
    position: absolute;
    left: 0;
    top: -2px;
    width: 44px;
    height: auto;
  }
`;

const StyledIconButton = styled(IconButton)`
  background-color: #f8931f !important;
  color: #ffffff !important;
`;
// User session data
// const serviceLoginName = undefined;
// Shows petition signing and current balance obtaining methods
// const session = undefined;

// Callback to refer to successful login
// const callbackServerUserData = undefined;

const Menu = () => {
  // const [userBalance, setUserBalance] = useState(0);
  // const [userName, setUserName] = useState('');
  // const { initUAL } = useConnectWallet()

  // const init = () => {
  //     // const anchor = new Anchor([SUPPORTED_CHAINS.testnet], { appName: APP_NAME });
  //
  //     const divUal = document.createElement('div')
  //     divUal.setAttribute('id', 'ual-login');
  //     document.body.appendChild(divUal);
  //
  //     const divLoginRoot = document.getElementById('ual-login');
  //     //  const ual = new UALJs(ualCallback, [SUPPORTED_CHAINS.testnet], APP_NAME, [anchor], { containerElement: divLoginRoot });
  //     // ual.init()
  // }

  // const ualCallback = (userObject) => {
  //     const session = userObject[0];
  //     setUserName(session.accountName);
  //
  //     getBalance(session, session.accountName);
  // }

  // const getBalance = (session, userName) => {
  //     console.log("userName", userName)
  //     const balance = session.rpc.get_account(userName);
  //     balance.then((balance) => {
  //         console.log("balance",balance)
  //         setUserBalance(balance.core_liquid_balance)
  //     });
  // }

  // const isLogged = () => {
  //     const auth = !isEmpty(userName) && !isEmpty(session);
  //     return auth;
  // }

  // const handleLogin = () => {
  //   const ualButton: HTMLElement = document.querySelector(
  //     '.ual-button-gen'
  //   ) as HTMLElement;
  //   ualButton.click();
  // };

  useEffect(() => {
    // init()
  }, []);

  return (
    <Navbar>
      <Stack direction="row" spacing={2}>
        <Item>
          <ItemNumber>
            <img src="/images/icon-token-01.svg" />
            99,999
          </ItemNumber>
          <StyledIconButton>
            <AddIcon />
          </StyledIconButton>
        </Item>
        <Item>
          <ItemNumber>
            <img src="/images/icon-token-02.svg" />
            99,999
          </ItemNumber>
          <StyledIconButton>
            <AddIcon />
          </StyledIconButton>
        </Item>
      </Stack>
    </Navbar>
  );
};

export default Menu;
