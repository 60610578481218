import React, { Suspense, lazy } from 'react';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './App.css';
import PageLoader from 'components/PageLoader';
import InventoryDetail from 'views/Inventory/InventoryDetail';

const Home = lazy(() => import('views/Home'));
const NotFound = lazy(() => import('views/NotFound'));
const Inventory = lazy(() => import('views/Inventory'));
const Market = lazy(() => import('views/Market'));
const Incubator = lazy(() => import('views/Incubator'));
const Login = lazy(() => import('views/Login'));

const App: React.FC = () => {
  return (
    <Router>
      <Suspense fallback={<PageLoader />}>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/inventory">
            <Inventory />
          </Route>
          <Route path="/nft/:id">
            <InventoryDetail />
          </Route>
          <Route path="/market">
            <Market />
          </Route>
          <Route path="/incubator">
            <Incubator />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default App;
