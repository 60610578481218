import React from 'react';

import Button from '@mui/material/Button';
import styled from 'styled-components';

import StyledPageHeader from 'components/Header/PageHeader';
import MainLayout from 'components/Layout/MainLayout';
import Menu from 'components/Menu';

const PageContent = styled.div`
  padding: 32px 16px;
  width: 100%;
  height: 100%;
`;

const PageTitle = styled.div`
  background-color: #ffffff;
  padding: 0 16px;
  margin-top: -32px;
  margin-left: -16px;
  margin-right: -16px;
  height: 100px;
  display: flex;
  align-items: center;
`;

const InventoryDetail: React.FC = () => {
  return (
    <MainLayout bg={`/images/BG-01.jpg`}>
      <>
        <Menu />
        <PageContent>
          <PageTitle>
            <h2>Details</h2>
          </PageTitle>
          <StyledPageHeader>
            <Button variant="contained" href="/market">
              Market
            </Button>
          </StyledPageHeader>
        </PageContent>
      </>
    </MainLayout>
  );
};
export default InventoryDetail;
