import styled from 'styled-components';

/* const Page = styled(Container)`
  min-height: calc(100vh - 64px);
  padding-top: 16px;
  padding-bottom: 16px;
` */

type Props = {
  bg: string;
  children: JSX.Element;
};

const MainLayout = ({ bg, children }: Props) => {
  const LayoutMain = styled.div`
    background-image: url(${bg});
    background-position: 50%;
    background-size: cover;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
  `;
  return <LayoutMain>{children}</LayoutMain>;
};

export default MainLayout;
