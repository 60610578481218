import React from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { createRoot } from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from './theme';

import './index.css';

const container = document.getElementById('root');
const root = createRoot(container!);

// const myChain = {
//   chainId: 'f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12',
//   rpcEndpoints: [{
//     protocol: 'https',
//     host: 'testnet-wax.3dkrender.com',
//     port: 8888,
//   }]
// }

// const appName = 'DONGAPP'
// const scatter = new Scatter([myChain], { appName: appName })

// const KocmocXUAL = withUAL(App)

root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <App />
  </ThemeProvider>
);

reportWebVitals();
