import Button from '@mui/material/Button';
import styled from 'styled-components';

type Props = {
  children: JSX.Element;
};

const PageHeader = ({ children }: Props) => {
  const StyledPageHeader = styled.div`
    padding: 32px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `;
  return (
    <StyledPageHeader>
      <Button variant="contained" href="/">
        Back
      </Button>
      {children}
    </StyledPageHeader>
  );
};

export default PageHeader;
